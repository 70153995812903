// colors.js
const colors = {
    yellow: '#F5DC82',
    darkblue: '#1E2D48',
    lavender: '#978DE2',
    lightgreen: '#A8C686',
    teal: '#81F0E5',
    orange: '#FFA047',
    blue: '#507DBC',
    pink: '#FAA4BD',
    gray: '#AFBED1',
    white: '#FFFFFF',
    taube: '#D0DEF5'
};

export default colors;
