import React, {useEffect, useState} from 'react';
import {
    Box, Modal, IconButton, Typography, FormControl, InputLabel,
    Select, MenuItem, TextField, FormHelperText, Button
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import colors from '../styles/colors';
import '../styles/event-modal.css';


const EventModal = ({ isOpen, onClose, onSave, onDelete, selectedDate, selectedEvent }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isEditing, setIsEditing] = useState(!selectedEvent);
    const [eventAction, setEventAction] = useState(selectedEvent ? 'Urlaub bearbeiten' : 'Urlaub hinzufügen');
    const [eventTitle, setEventTitle] = useState('');
    const [eventType, setEventType] = useState('');
    const [startDate, setStartDate] = useState(selectedDate);
    const [endDate, setEndDate] = useState('');

    const [eventTitleError, setEventTitleError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');

    useEffect(() => {
        setStartDate(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        if (isOpen) {
            if (selectedEvent) {
                setIsEditing(false);
                setEventTitle(selectedEvent.title);
                setEventType(selectedEvent.title);
                setEventAction('Urlaub bearbeiten');
                setStartDate(selectedEvent.start);
                setEndDate(selectedEvent.end);
            } else {
                resetValues();
            }
        }
    }, [isOpen, selectedEvent]);

    const resetValues = () => {
        setIsEditing(true);
        setEventTitle('');
        setStartDate('');
        setEndDate('');
        setEventType('');
        setEventAction('Urlaub hinzufügen');
        setEventTitleError('');
        setStartDateError('');
        setEndDateError('');
    };

    const handleClose = () => {
        onClose();
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const saveEvent = async (event, eventId) => {
        const url = eventId ? 
            `${process.env.REACT_APP_BACKEND_URL}/events/${eventId}` : 
            `${process.env.REACT_APP_BACKEND_URL}/events`;
    
        const method = eventId ? 'PUT' : 'POST';
    
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        });
        
        if (!response.ok) {
            enqueueSnackbar('Fehler beim Speichern des Ereignisses:', { variant: 'error' });
            throw new Error(`Fehler beim Speichern des Ereignisses: Error: ${response}`);
        } else {
            enqueueSnackbar('Ereignis erfolgreich gespeichert!', { variant: 'success' });
        }
        return await response.json();
    };
    
    const handleSaveClick = async () => {
        let isValid = true;
    
        if (!eventTitle) {
            setEventTitleError('Name ist erforderlich.');
            isValid = false;
        }
    
        if (!startDate) {
            setStartDateError('Startdatum ist erforderlich.');
            isValid = false;
        }
    
        if (!endDate) {
            setEndDateError('Enddatum ist erforderlich.');
            isValid = false;
        } else if (startDate > endDate) {
            setEndDateError('Enddatum muss gleich oder größer als Startdatum sein.');
            isValid = false;
        }
    
        if (isValid) {
            const newEvent = {
                name: eventTitle,
                startDate: startDate,
                endDate: endDate,
            };
                  
            try {
                await saveEvent(newEvent, selectedEvent?.id);
                resetValues()
                onSave();
            } catch (error) {
                console.error('Fehler beim Speichern des Ereignisses:', error);
            }
        }
    };

    const deleteEvent = async (eventId) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/events/${eventId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        
        if (response.status !== 200) {
            enqueueSnackbar('Fehler beim Löschen des Ereignisses:', { variant: 'error' });
            throw new Error('Fehler beim Löschen des Ereignisses:', response);
        } else {
            enqueueSnackbar('Ereignis erfolgreich gelöscht!', { variant: 'success' });
        }
    };

    const handleDeleteClick = async () => {
        if (window.confirm('Möchten Sie dieses Ereignis wirklich löschen?')) {
            try {
                await deleteEvent(selectedEvent.id); // Assuming the selected event has an 'id' property
                onDelete()
            } catch (error) {
                console.error('Fehler beim Löschen des Ereignisses:', error);
            }
        }
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: { xs: '95vw', sm: 400 },
                    bgcolor: 'background.paper', 
                    boxShadow: 24, p: 4,
                    borderRadius: '0.5rem'
                }}
            >
                <ModalHeader
                    isEditing={isEditing}
                    selectedEvent={selectedEvent}
                    handleClose={handleClose}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                />
                <ModalBody
                    isEditing={isEditing}
                    eventAction={eventAction}
                    eventType={eventType}
                    setEventType={setEventType}
                    eventTitle={eventTitle}
                    setEventTitle={setEventTitle}
                    eventTitleError={eventTitleError}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    startDateError={startDateError}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    endDateError={endDateError}
                    selectedEvent={selectedEvent}
                />
                <ModalFooter 
                    isEditing={isEditing}
                    handleClose={handleClose}
                    handleSaveClick={handleSaveClick}
                />
            </Box>
        </Modal>
    );
}


const ModalHeader = ({ isEditing, selectedEvent, handleClose, handleDeleteClick, handleEditClick }) => (
    <div style={{paddingBottom: '1rem', paddingTop: '0.5rem'}}>
        {isEditing && selectedEvent ? (
            <IconButton onClick={handleDeleteClick} sx={{ position: 'absolute', top: 0, right: 40 }}>
                <DeleteOutlineOutlinedIcon />
            </IconButton>
        ) : selectedEvent ? (
            <>
                <IconButton onClick={handleDeleteClick} sx={{ position: 'absolute', top: 0, right: 80 }}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
                <IconButton onClick={handleEditClick} sx={{ position: 'absolute', top: 0, right: 40 }}>
                    <EditOutlinedIcon />
                </IconButton>
            </>
        ) : null}

        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
        </IconButton>
    </div>
);

const ModalBody = ({
    isEditing, eventAction, eventType, setEventType, eventTitle, setEventTitle,
    eventTitleError, startDate, setStartDate, startDateError, endDate,
    setEndDate, endDateError, selectedEvent
}) => (
    <>
        {isEditing ? (
            <>
                <Typography variant="h5" align="center" marginBottom="1.25rem">
                    {eventAction}
                </Typography>
                <FormControl fullWidth sx={{ mb: 4 }}>
                    <InputLabel id="event-type-label">Name</InputLabel>
                    <Select
                        labelId="event-type-label"
                        id="event-type-select"
                        value={eventType}
                        onChange={(e) => {
                            setEventType(e.target.value);
                            setEventTitle(e.target.value);
                        }}
                        displayEmpty
                        placeholder="Bitte auswählen"
                        label="Name"
                        error={!!eventTitleError}
                    >
                        <MenuItem value="Lutz & Moni">Lutz & Moni</MenuItem>
                        <MenuItem value="Charly">Charly</MenuItem>
                        <MenuItem value="Corn">Corn</MenuItem>
                        <MenuItem value="Balthi">Balthi</MenuItem>
                        <MenuItem value="Till">Till</MenuItem>
                        <MenuItem value="Anne & Conny">Anne & Conny</MenuItem>
                        <MenuItem value="Vicky & Daniel">Vicky & Daniel</MenuItem>
                        <MenuItem value="Andere">Andere</MenuItem>
                    </Select>
                    {eventType === 'Andere' && (
                        <TextField 
                            label="Andere" 
                            fullWidth sx={{ mb: 2 }} 
                            value={eventTitle}
                            onChange={(e) => setEventTitle(e.target.value)}
                        />
                    )}
                    {eventTitleError && (
                        <FormHelperText error>{eventTitleError}</FormHelperText>
                    )}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 4 }}>
                    <TextField
                        label="Startdatum"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{ mb: 0.5 }}
                        value={startDate ?? ''}
                        onChange={(e) => setStartDate(e.target.value)}
                        error={!!startDateError}
                        disabled={!isEditing}
                    />
                    {startDateError && (
                        <FormHelperText error>{startDateError}</FormHelperText>
                    )}

                </FormControl>
                <FormControl fullWidth sx={{ mb: 4 }}>
                    <TextField
                        label="Enddatum"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{ mb: 0.5 }}
                        value={moment(endDate).format('YYYY-MM-DD') ?? ''}
                        onChange={(e) => setEndDate(e.target.value)}
                        inputProps={{ min: startDate }}
                        error={!!endDateError}
                        disabled={!isEditing}
                    />
                    {endDateError && (
                        <FormHelperText error>{endDateError}</FormHelperText>
                    )}
                </FormControl>
            </>
        ) : (
            selectedEvent && (
                <>
                    <Typography variant="h5" gutterBottom >
                        {selectedEvent.title}
                    </Typography>
                    <Typography variant="subtitle1" >
                        Startdatum: {moment(selectedEvent.start).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography variant="subtitle1" >
                        Enddatum: {moment(selectedEvent.end).subtract(1, 'days').format('DD.MM.YYYY')}
                    </Typography>
                </>
            )
        )}
    </>
);

const ModalFooter = ({ isEditing, handleClose, handleSaveClick }) => (
    <Box display="flex" justifyContent="space-between" marginTop="1rem">
        <Button 
            variant="outlined" 
            onClick={handleClose}
            sx={{
            backgroundColor: colors.white,
            borderColor: colors.darkblue,
            color: colors.darkblue,
            '&:hover': {
                textDecoration: 'underline',
                backgroundColor: colors.white,
            },
            }}
        >
            Abbrechen
        </Button>
        <Button 
            variant="contained" 
            onClick={handleSaveClick}
            sx={{
                backgroundColor: colors.darkblue,
                boxShadow: 'none',
                color: colors.white,
                '&:hover': {
                    backgroundColor: colors.darkblue,
                    boxShadow: 'none',
                    textDecoration: 'underline',
                },
            }}
        >
            {isEditing ? 'Speichern' : 'Aktualisieren'}
        </Button>
    </Box>
);

export default EventModal;
