import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, CssBaseline } from '@mui/material/';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import moment from 'moment';
import EventModal from './components/EventModal';
import CustomCalendar from './components/CustomCalendar';
import Login from './components/Login';
import colors from './styles/colors';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function App() {
  const { enqueueSnackbar } = useSnackbar();

  // Get the stored authentication status and timestamp from localStorage
  const isAuthenticatedInLocalStorage = localStorage.getItem('isAuthenticated') === 'true';
  const authenticatedAt = new Date(localStorage.getItem('authenticatedAt') || 0);
  const expirationDuration = 24 * 60 * 60 * 1000;  // 24 hours in milliseconds
  const hasExpired = new Date() - authenticatedAt > expirationDuration;

  const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedInLocalStorage && !hasExpired);

  const [events, setEvents] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);
  
  useEffect(() => {
    if (hasExpired && isAuthenticatedInLocalStorage) {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('authenticatedAt');
        setIsAuthenticated(false);
    }
}, [hasExpired, isAuthenticatedInLocalStorage]);

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/events`);
      const data = await response.json();
      const transformedEvents = data.map(r => ({
        id: r.id,
        title: r.name,
        start: moment(r.startdate).format('YYYY-MM-DD'),
        end: moment(r.enddate).add(1, 'days').format('YYYY-MM-DD')
      }));
      setEvents(transformedEvents);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to fetch events.', { variant: 'error' });
    }
  };

  const handleModalOperations = (event = null, date = null) => {
    setSelectedEvent(event);
    setSelectedDate(date);
    setModalOpen(!!(event || date));
    if (!event && !date) fetchEvents();
  };
  

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Box style={{ backgroundColor: colors.taube }}>
          <CssBaseline />
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              {isAuthenticated ? (
                <CustomCalendar
                  events={events}
                  onEventSelect={event => handleModalOperations(event)}
                  onDayClick={date => handleModalOperations(null, date)}
                />
              ): (
                <Login onLogin={() => setIsAuthenticated(true)} />
              )
              }
              </Grid>
            </Grid>
          </Container>
          <EventModal
            isOpen={isModalOpen}
            onClose={() => handleModalOperations()}
            onSave={() => handleModalOperations()}
            onDelete={() => handleModalOperations()}
            selectedDate={selectedDate}
            selectedEvent={selectedEvent}
          />
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
