import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, Button, useMediaQuery, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../styles/login.css';
import colors from '../styles/colors';

const Login = ({ onLogin }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (username === process.env.REACT_APP_ADMIN_USERNAME && password === process.env.REACT_APP_ADMIN_PASSWORD) {
        const now = new Date();
        localStorage.setItem('authenticatedAt', now.toString());  // Store the timestamp
        localStorage.setItem('isAuthenticated', 'true'); 
        onLogin();
    } else {
        alert('Falsche Anmeldedaten');
    }
};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        maxWidth: '100vw',
        overflow: 'hidden'
      }}
    >
      <Paper
        sx={{
          padding: isMobile ? '0.75rem' : '1.5rem',
          textAlign: 'center',
          borderRadius: '0.5rem',
          '@media (max-width: 600px)': {
            margin: 0,
          },
        }}
      >
        <Box 
          item container justifyContent="center" style={{ marginBottom: '0.25rem', marginTop: '0.5rem'}}>
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" style={{ height: '40px', width: '40px' }}/>
        </Box>
        <Typography variant="h5" sx={{ marginBottom: '1.25rem', color: colors.darkblue }}>
          Einloggen
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          size="large"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          size="large"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ 
            borderRadius: '0.5rem', 
            backgroundColor: colors.darkblue,
            marginTop: '1rem',
            '&:hover': {
              backgroundColor: colors.blue,
            }, 
          }}
          onClick={handleSubmit}
        >
          Einloggen
        </Button>
      </Paper>
    </Box>
  );
};

export default Login;

