import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/de';
import colors from '../styles/colors';
import '../styles/calendar.css';

const localizer = momentLocalizer(moment); 

const EVENT_COLORS = {
  'Anne & Conny': colors.lavender,
  'Balthi': colors.lightgreen,
  'Charly': colors.yellow,
  'Corn': colors.teal,
  'Lutz & Moni': colors.orange,
  'Till': colors.blue,
  'Vicky & Daniel': colors.pink,
  'default': colors.gray
};


const CustomToolbar = (toolbar) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const goToToday = () => {
    toolbar.onView(Views.MONTH);
    toolbar.onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar">
      <Grid container direction="column">
        <Grid 
          item container justifyContent="center" style={{ marginBottom: isMobile ? '0.25rem': '0rem', marginTop: isMobile ? '0.5rem': '0rem' }}>
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" style={{ height: isMobile ? '2rem' : '2.5rem', width: isMobile ? '2rem' : '2.5rem'}}/>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={3} container justifyContent="flex-start" alignItems="center">
            <Box display="flex">
              <IconButton
                onClick={() => toolbar.onNavigate('PREV')}
                style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: isMobile ? '0rem' : '0.375rem 1rem' }}
              >
                <ArrowBackIcon style={{ color: colors.darkblue }} />
              </IconButton>
              <IconButton
                onClick={() => toolbar.onNavigate('NEXT')}
                style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: isMobile ? '0rem' : '0.375rem 1rem' }}
              >
                <ArrowForwardIcon style={{ color: colors.darkblue }} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={6} container justifyContent="center">
            <Typography 
              variant="h5" 
              align="center" 
              color={colors.darkblue} 
            >
              {moment(toolbar.date).format('MMMM YYYY')}
            </Typography>
          </Grid>

          <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
            <Button 
              style={{ 
                backgroundColor: colors.darkblue,
                borderColor: 'transparent',
                '&:hover': {
                  backgroundColor: colors.darkblue,
                },
                color: colors.white,
                marginRight: isMobile ? '1rem': '0rem',
                padding: isMobile ? '0.375rem 0rem' : '0.375rem 1rem',
              }} 
              onClick={goToToday}>Heute</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const CustomCalendar = ({ events, onEventSelect, onDayClick }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const eventStyleGetter = (event) => ({
    style: {
        backgroundColor: EVENT_COLORS[event.title] || EVENT_COLORS.default,
        color: colors.darkblue,
    }
  });

  const dayPropGetter = (date) => {
    const isToday = moment(date).isSame(moment(), 'day');
    return {
      className: isToday ? 'custom-calendar-cell current-day' : 'custom-calendar-cell',
      style: isToday ? { backgroundColor: colors.taube } : {},
    };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: isMobile ? 'flex-start' : 'center',
        minHeight: '100vh',
        width: isMobile ? '100vw' : 'auto'
      }}
    >
      <Box sx={{ height: isMobile ? '100vh' : '90vh', width: '100%', borderRadius: '0.25rem'}}>
        <Calendar
          localizer={localizer}
          events={events}
          views={['month']}
          style={{ 
            height: '100%', 
            backgroundColor: colors.white, 
            padding: isMobile ? '0rem' : '2rem', 
            borderRadius: '0.5rem',
            '&:focus': {
              outline: 'none',
            },
          }}
          onSelectEvent={onEventSelect}
          onSelectSlot={(slotInfo) => {
            onDayClick(moment(slotInfo.start).format('yyy-MM-dd'));
          }}
          selectable={true}
          components={{
            toolbar: CustomToolbar,
          }}
          eventPropGetter={eventStyleGetter}
          dayPropGetter={dayPropGetter}
        />
      </Box>
    </Box>
  );
};

export default CustomCalendar;
